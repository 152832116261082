body {
  background-color: #FFFFFF;
  color: #000000;
  text-align: center;
  font-size: 20px;
  font-family: 'Quicksand';
  padding-top: 5%;
  word-wrap: break-word;
}

.logo {
  width: 250px;
  height: 250px;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.WalletInfo {
  width: 500px;
}

.Block {
  border: 1px solid #fff;
  padding: 5%;
  margin: 2%;
}

.Transaction {
  padding: 5%;
}

.ConductTransaction, .TransactionPool {
  margin: 10%;
}
